

















































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class RecommendList extends Vue {
  public user_id = "";
  public list: any[] = []; // 数据的列表
  public refresh = 0; // 页面允许刷新（为了list的改变）

  public search = ""; // 搜索框的值
  public search_value = ""; // 搜索的值

  public filteList = [
    {text:'全部',value:''},
    {text:'店中店',value:'1'},
    {text:'艾灸馆',value:'2'},
  ];
  public filteIndex = 0

  filte(index){
    this.filteIndex = index
    this.init()
  }
  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }

  // 搜索
  onSearch() {
    this.search_value = this.search;
  }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.list = [];
    this.refresh++;
  }
}
